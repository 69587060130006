import React, { Fragment, useState, useEffect, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import TooltipComponent from "../../components/TooltipComponent";
import { TitleH1Component } from "../../components/TitleH1Component";
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../components/TitleH2Component";
import SelectBenefitsComponent from "../../components/SelectBenefitsComponent";
import { useCotizador } from "../../context/apiContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSteps } from "../../hooks/useSteps";
import Picture from "../../components/PictureComponent";
import { TitleH3Component } from "components/TitleH3Component";
import styleTexto from '../../scss/text.module.scss';
import ValidaComponent from "../../components/ValidaComponent";
const Step5Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { getBenefitsPerStep } = useSteps();
    const { benefits, contextState, handleOnChange, outpationBenefitsEnabled, outpationBenefitsEnabled4fases, nonAmbulance, handleChecks } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        verifyCurrentLanguage();
        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const paragraphgeneral_header_title = texts?.general_header_title;
    const general_header_title_marca = paragraphgeneral_header_title?.replace?.("®", `<span>&reg;</span>`) ?? '';
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container pb-3">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            <p dangerouslySetInnerHTML={{ __html: general_header_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>
                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 mb-0 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>
                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-3 ${styleTexto.tamasubparrafo} ${Stylegeneral.titulo}`}>
                        {texts?.step5_subtitulo_planbase ?? "El plan base de My VIP Care ofrece beneficios de hospitalización y algunos beneficios ambulatorios principales."}
                    </TitleH3Component>
                </div>
            </section>
            <section className="pb-md-5 pb-lg-0">
                <div className="container">

                    <div className={`${Stylegeneral.rowForm} row gy-5 `}>


                        {/* <div className={`col-12 col-md-4 ${Stylegeneral.colform}` }>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div  className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>Injuries during the training or practice of hazardous professional sports</label>
                                    <TooltipComponent text="Este es un tooltip "><Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} /></TooltipComponent>
                                </div>

                                <select className={`${styleForm.customselectLine}`}>
                                    <option value="opcion1">???</option>
                                </select>
                            </div>
    </div>*/}
                        <div className={`col-12 col-md-6 col-lg-5 `}>

                            <Picture src="/img/step5img.png" styleGroup={``} alt="VUMI" />
                        </div>
                        <div className={`col-12 col-md-6 col-lg-7  `}>
                            <div className="d-flex justify-content-strat align-items-center">
                                <div className={`${styleForm.btn_activar} pb-3 `}>
                                    <div className={`d-flex justify-content-center  align-items-center mb-2 ${styleForm.con_tootip}`}>

                                        <label htmlFor="need" className={`${styleForm.labels} raleway  mb-2 color_primary_2 fw_700 pe-1`}>
                                            {texts?.step5_checkbox_phrase ?? 'Do you want to add outpatient benefits?'}
                                        </label>
                                        <OverlayTrigger
                                            key={isMobileDevice ? 'right' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                                {texts?.Tooltip_cobertura ?? 'Cobertura para beneficios ambulatorios como atención en sala de emergencia, visitas a médicos y especialistas, estudios diagnósticos, cirugía ambulatoria, equipo médico durable, terapia física, cuidados en el hogar, enfermedad de Alzheimer, aparatos auditivos, terapia ocupacional, autismo, trastornos del sueño, tratamientos de alergias y/o tratamientos quirúrgicos de trastornos sintomáticos de los pies.'}
                                            </Tooltip>}>
                                            <div className={` ${styleForm.iconselect} mb-2`}>
                                                <Icon icon={'mdi:information-outline'} className={` color_gray_5`} />

                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                        <input className="form-check-input" type="checkbox" id="need" name={"outpationBenefitsEnabled"} onChange={handleChecks} checked={outpationBenefitsEnabled} />
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid">
                                <div className="row gy-4">
                                    <div className={`col-12   ${Stylegeneral.colform}`}>
                                        <div className={`${styleForm.form_group} ${styleForm.form_h}  ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                            <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                                <label htmlFor="seclet" className={`${styleForm.labels}  ${styleForm.labelstextlarge}  raleway  color_primary_2 pe-1 `}>
                                                    {texts?.step11_form_field_outpatient ?? 'Maximum outpatient coverage per insured, per policy year'}
                                                </label>

                                                <OverlayTrigger
                                                    key={isMobileDevice ? 'left' : 'top'}
                                                    placement={isMobileDevice ? 'left' : 'top'}
                                                    overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_outpatient ?? 'Services of treatments that do not require a hospital admission or hospital stay for more than twenty-three (23) hours'}</Tooltip>}
                                                >
                                                    <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                                </OverlayTrigger>
                                            </div>

                                            <SelectBenefitsComponent benefits={benefits.debug} benefit={"Maximum outpatient coverage per insured, per policy year"} className={`${styleForm.customselectLine}`} />

                                        </div>

                                    </div>
                                    <div className={`col-12   ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                        <TitleH2Component styleGroup={` ${styleForm.subtext} raleway fw_700  color_primary_2 mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                                            {texts?.step5_Text_subtitulo_divisor ?? "Coberturas adicionales ambulatorias"}
                                        </TitleH2Component>

                                        {/*<div className={`${styleForm.btn_activar} pb-3 `}>
                                            <div className={`${styleForm.con_tootip}`}>
                                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.minText} raleway  mb-2 color_primary_2 fw_700 pe-1`}>
                                                    {texts?.step5_Text_subtitulo_divisor ?? "COBERTURAS ADICIONELAS AMBULATORIAS"}
                                                </label>
                                            </div>

                                            <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                                <input className="form-check-input" type="checkbox" id="need" name={"outpationBenefitsEnabled4fases"} onChange={handleChecks} checked={outpationBenefitsEnabled4fases} />
                                            </div>
                                       </div>*/}
                                    </div>
                                    {getBenefitsPerStep().includes('Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture') && <div className={`col-12 col-lg-12  ${Stylegeneral.colform} ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                        <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                            <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                                <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                                    {texts?.step5_form_field_therapy ?? 'Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture'}
                                                </label>

                                            </div>

                                            <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture"} className={`${styleForm.customselectLine}`} />
                                        </div>
                                    </div>}

                                    {/*getBenefitsPerStep().includes('Prescribed medications') && <div className={`col-12 col-lg-6  ${Stylegeneral.colform} ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                        <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                            eliminar
                                            <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                                <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                                    {texts?.step5_form_field_medications ?? 'Prescribed medications'}
                                                </label>
                                                <OverlayTrigger
                                                    key={isMobileDevice ? 'left' : 'top'}
                                                    placement={isMobileDevice ? 'top' : 'top'}
                                                    overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_medications ?? 'Medication prescribed by a physician that would not be available without such prescribtion. Certain treatments and medications, such as vitamins, herbs, aspirin, cold remedies, and experimental or investigative medication or supplies, do not qualify as prescription medication, even when recommended by a physician'}</Tooltip>}
                                                >
                                                    <div className={`${styleForm.iconselect}`}>
                                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>

                                            <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Prescribed medications"} className={`${styleForm.customselectLine}`} />

                                        </div>
                                    </div>*/}
                                    {/*getBenefitsPerStep().includes('Mental health prescription medication') && <div className={`col-12 col-lg-6  ${Stylegeneral.colform} ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                        <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                            eliminar
                                            <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                                <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                                    {texts?.step5_form_field_mental_health ?? 'Mental health prescription medication'}
                                                </label>
                                                <OverlayTrigger
                                                    key={isMobileDevice ? 'left' : 'top'}
                                                    placement={isMobileDevice ? 'left' : 'top'}
                                                    overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_mental_health ?? 'Medication with a prescription written by a physician to treat a mental health condition.'}</Tooltip>}
                                                >
                                                    <div className={`${styleForm.iconselect}`}>
                                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>

                                            <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Mental health prescription medication"} className={`${styleForm.customselectLine}`} />

                                        </div>
                                    </div>*/}
                                    {/*getBenefitsPerStep().includes('Refractive eye surgery') && <div className={`col-12 col-lg-6   ${Stylegeneral.colform} ${outpationBenefitsEnabled ? '' : 'd-none'}`}>
                                        <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                            eliminar
                                            <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                                <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                                    {texts?.step5_form_field_surgery ?? 'Refractive surgery'}
                                                </label>
                                                <OverlayTrigger
                                                    key={isMobileDevice ? 'left' : 'top'}
                                                    placement={isMobileDevice ? 'top' : 'top'}
                                                    overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_surgery ?? 'A surgical procedure used to correct or improve vision problems, such as nearsightedness (myopia), farsightedness (hyperopia), astigmatism or presbyopia.'}</Tooltip>}
                                                >
                                                    <div className={`${styleForm.iconselect}`}>
                                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>

                                            <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Refractive eye surgery"} className={`${styleForm.customselectLine}`} />

                                        </div>
                                    </div>*/}

                                    <div className="col-12">
                                        <div className={`${!outpationBenefitsEnabled ? 'mt-3' : 'd-none'}`}>
                                            <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                                                <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow`}>
                                                    {texts?.step11_provider_message_title ?? 'Important provider networks message:'}
                                                </TitleH2Component>
                                                <div className={`form-check mt-4 `}>
                                                    <input className="form-check-input" type="checkbox" value="" id="acepto" name={"nonAmbulance"} checked={nonAmbulance} onChange={handleChecks} />
                                                    <label className={`Poppins fw_700  typecursiva color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                                                        {texts?.general_dislcaimer_nobeneficios ?? 'He elegido no optar por beneficios ambulatorios adicionales. Entiendo que mi póliza no ofrecerá ciertos beneficios para tratamientos y/o servicios ambulatorios.'}
                                                    </label>
                                                    <div className={`${styleForm.validador}`}>
                                                        <ValidaComponent keyName={"nonAmbulance"} styleGroup={`${styleForm.valida}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>

                        </div>

                    </div>
                    <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                        <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow`}>
                            {texts?.step5_provider_message_title ?? 'Mensaje importante!!!'}
                        </TitleH2Component>
                        <div className={` mt-1`}>
                            <label className={`Poppins fw_700  typecursiva color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                                {texts?.step5_disclaimer_mensaje ?? '!!Este módulo incluye beneficios para sala de emergencia, visitas a médicos y especialistas, servicios de estudios diagnósticos, cirugía ambulatoria, honorarios del cirujano, asistente del cirujano y/o anestesiólogo, prótesis y aparatos médicos implantados durante una cirugía, equipo médico durable, terapia física y de rehabilitación, medicamentos de prescripción ambulatoria, cuidados de enfermero(a) o servicios de terapista en el hogar, tratamientos para la enfermedad de Alzheimers, aparatos auditivos, tratamientos de alergias y tratamiento quirúrgico de trastornos sintomáticos de los pies.'}
                                <br />
                                <br />
                                {texts?.step5_disclaimer_mensaje2 ?? '!!Este módulo incluye beneficios para sala de emergencia, visitas a médicos y especialistas, servicios de estudios diagnósticos, cirugía ambulatoria, honorarios del cirujano, asistente del cirujano y/o anestesiólogo, prótesis y aparatos médicos implantados durante una cirugía, equipo médico durable, terapia física y de rehabilitación, medicamentos de prescripción ambulatoria, cuidados de enfermero(a) o servicios de terapista en el hogar, tratamientos para la enfermedad de Alzheimers, aparatos auditivos, tratamientos de alergias y tratamiento quirúrgico de trastornos sintomáticos de los pies.'}
                            </label>
                        </div>
                    </div>

                </div>
            </section>
        </Fragment>
    )
}
export default Step5Pages;