import { TitleH1Component } from "components/TitleH1Component";
import React, { Fragment, useContext, useState, useEffect } from "react";
import Stylegeneral from '../../scss/general.module.scss';
import { TextsContext } from "context/textsContext";
import Picture from "components/PictureComponent";
import SelectBenefitsComponent from "components/SelectBenefitsComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCotizador } from "context/apiContext";
import styleForm from '../../scss/form.module.scss';
import { Icon } from "@iconify/react";
const Step13Page = () => {
    const { texts } = useContext(TextsContext);
    const { benefits, outpationBenefitsdeducible, handleChecks, step13Confirmation } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);




    useEffect(() => {

        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container pb-3">
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 mb-0 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>
                </div>
            </section>
            <section className="pb-md-5 pb-lg-0">
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 `}>
                        <div className={`col-12 col-md-6 col-lg-5 d-flex justify-content-center`}>
                            <Picture src="/img/deducible.png" styleGroup={`w-auto ${Stylegeneral.hvImg_nuevosStep}`} alt="VUMI" />
                        </div>
                        <div className={`col-12 col-md-6 col-xxl-7`}>
                            <div className={`${Stylegeneral.wForm}`}>
                                <div className="d-flex justify-content-strat align-items-center">
                                    <div className={`${styleForm.btn_activar} pb-3 `}>
                                        <div className={`d-flex justify-content-center  align-items-center mb-2 ${styleForm.con_tootip}`}>

                                            <label htmlFor="need" className={`${styleForm.labels} ms-2 ms-md-0 raleway  mb-2 color_primary_2 fw_700 pe-1`}>
                                                {texts?.step13_checkbox_phrase ?? '¿Desea añadir una reduccion del deduciblede hasta $5000 en caso de un accidente serio?'}
                                            </label>
                                            <OverlayTrigger
                                                key={isMobileDevice ? 'right' : 'top'}
                                                placement={isMobileDevice ? 'left' : 'left'}
                                                overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                                    {texts?.step13_Tooltip_anade_reducible ?? 'Cobertura para beneficios ambulatorios como atención en sala de emergencia, visitas a médicos y especialistas, estudios diagnósticos, cirugía ambulatoria, equipo médico durable, terapia física, cuidados en el hogar, enfermedad de Alzheimer, aparatos auditivos, terapia ocupacional, autismo, trastornos del sueño, tratamientos de alergias y/o tratamientos quirúrgicos de trastornos sintomáticos de los pies.'}
                                                </Tooltip>}>
                                                <div className={` ${styleForm.iconselect} mb-2`}>
                                                    <Icon icon={'mdi:information-outline'} className={` color_gray_5`} />

                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                        <input className="form-check-input" type="checkbox" id="need" name={"step13Confirmation"} onChange={handleChecks} checked={step13Confirmation} />

                                        </div>
                                    </div>
                                </div>

                  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Step13Page;