export const getEnvVariables = () => {
  return {
    API_URL: 'https://api.vumi-modulos.iridian.co/api/',
    BACK_BASE_URL: 'https://api.vumi-modulos.iridian.co/',
    API_LOGIN: 'https://vumi-agents.iridian.co/api',
    // VUMI_LOGIN_URL: "https://agentsportal.vumigroup.com/index.php/vip_care/login",
    VUMI_LOGIN_URL: "https://agentsportal.vumigroup.com/index.php/vip_care/login",
    VUMI_LOGIN_URL_TEST: "https://agentsportal.vumigroup.com/index.php/vip_care/login",
    DEBUG: false
  }
}

