import { TitleH1Component } from "components/TitleH1Component";
import React, { Fragment, useContext, useState, useEffect } from "react";
import Stylegeneral from '../../scss/general.module.scss';
import { TextsContext } from "context/textsContext";
import Picture from "components/PictureComponent";
import SelectBenefitsComponent from "components/SelectBenefitsComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCotizador } from "context/apiContext";
import styleForm from '../../scss/form.module.scss';
import { Icon } from "@iconify/react";
const Step14Page = () => {
    const { texts } = useContext(TextsContext);
    const { benefits, outpationBenefitsrevisiones, handleChecks, step14Confirmation } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {

        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container pb-3">
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 mb-0 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title_anexos ?? "Let’s continue customizing based on your exact needs"} 
                    </TitleH1Component>
                </div>
            </section>
            <section className="pb-md-5 pb-lg-0">
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 `}>
                        <div className={`col-12 col-md-6 col-lg-5 d-flex justify-content-center`}>
                            <Picture src="/img/Check.png" styleGroup={`w-auto ${Stylegeneral.hvImg_nuevosStep}`} alt="VUMI" />
                        </div>
                        <div className={`col-12 col-md-6 col-xxl-7`}>
                            <div className={`${Stylegeneral.wForm}`}>
                                <div className={`${styleForm.btn_activar} pb-3 `}>
                                    <div className={`${styleForm.con_tootip}`}>
                                        <label htmlFor="need" className={`${styleForm.labels}  raleway  mb-2 color_primary_2 fw_700 pe-1`}>
                                            {texts?.step14_Text_Agg_revisiones_salud ?? "¿Quiere agregar beneficios de maternidad?"}
                                        </label>
                                    </div>

                                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                        <input className="form-check-input" type="checkbox" id="need" name={"step14Confirmation"} onChange={handleChecks} checked={step14Confirmation} />
                                    </div>
                                </div>

                                <div className={`${Stylegeneral.colform} `}>
                                    <div className={`${styleForm.form_group} ${styleForm.form_h}  ${step14Confirmation ? '' : 'd-none'}`}>
                                        <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                            <label htmlFor="seclet" className={`${styleForm.labels}  ${styleForm.labelstextlarge}  raleway  color_primary_2 pe-1 `}>
                                                {texts?.step14_form_field_outpatient ?? 'Chequeo medico preventino (periodo de espera de 3 meses sin deducible, sin coaseguro en caso de aplicar)'}
                                            </label>

                                            <OverlayTrigger
                                                key={isMobileDevice ? 'left' : 'top'}
                                                placement={isMobileDevice ? 'left' : 'top'}
                                                overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step4_tooltip_checkup ?? 'Services of treatments that do not require a hospital admission or hospital stay for more than twenty-three (23) hours'}</Tooltip>}
                                            >
                                                <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                            </OverlayTrigger>
                                        </div>

                                        <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Preventive health checkup"} className={`${styleForm.customselectLine}`} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Step14Page;