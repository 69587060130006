import React, { Fragment, useContext } from 'react'
import { TextsContext } from '../context/textsContext';
import Special from "../scss/text.module.scss";
import StyleStep from "../scss/step.module.scss";
import {useNavigate} from "react-router-dom";
import {useSteps} from "../hooks/useSteps";
import { useValidation } from '../hooks/ValidationHook';
import { Icon } from '@iconify/react';

const StepComponents = () => { 
	const { texts } = useContext(TextsContext);
	const {validateValues} = useValidation()
	const {getAllSteps} = useSteps();
	const data = [
		{ id: 1, options: texts?.menu_options_title_personal ?? 'Personal Information', state:true },
		{ id: 2, options: texts?.menu_options_title_coverage ?? 'Coverage Options', state:false},
		{ id: 3, options: texts?.menu_options_title_benefits ?? 'Plan Benefits', state:false },
		{ id: 4, options: texts?.menu_options_title_benefits_ambulatorios ?? ' Beneficios ambulatorios', state:false },
		{ id: 5, options: texts?.menu_options_title_reduccion_deducible ?? 'Reducción del Deducible', state:false },
		{ id: 6, options: texts?.menu_options_title_riders ?? 'Riders', state:false },
		{ id: 7, options: texts?.menu_options_title_summary ?? 'Summary', state:false },
	];

	const navigate = useNavigate();

	const currentPath = window.location.pathname;
	const currentPathData = getAllSteps().find(i => i?.path === currentPath);


	const handleClick = (module) => {
		validateValues();
		navigate(getAllSteps().find(i => i?.module == module && !i?.ignore).path);
	};

  return (
	<Fragment>
		<ul className={StyleStep.StyleStep}>
			{
				data.map((user, idx) => (
					<li key={user.id} className={`${StyleStep.step} 
					${currentPathData?.module > user.id && StyleStep.stepPastVisited} 
					${currentPathData?.module == user.id && StyleStep.stepVisited}
					${currentPathData?.module < user.id && StyleStep.stepNoVisited} `}>
						<span className={StyleStep.dot}>
							<Icon icon="mdi:check-bold" className={StyleStep.icon} />
						</span>
						<span onClick={() => handleClick(user.id)} className={`${StyleStep.texto}`}>{user.options}</span>
					</li>
				))
			}
		</ul>
	</Fragment>
  )
}

export default StepComponents
