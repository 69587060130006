/* eslint-disable eqeqeq */
import { useEffect } from "react";
import { FEMALE_BENEFITS, useCotizador } from "../context/apiContext";
import { getAge, getCountry, userIsFemale } from "../helpers/toolsHelper";
import {useSteps} from "./useSteps";



export const useCalculations = () => {
    const {contextState, quora, calculation, setCalculation} = useCotizador();
    const {getBenefitsPerStep, getCurrentStepData} = useSteps();

    const calculateFactors = (value, travel = false, isFem = false) => {
        value = Number(value);
        const filteredSelected = Object.entries(contextState.selectedBenefits)
        .filter(([key, value_]) => {
            return !!getBenefitsPerStep().includes(key);
        })
        .filter(([key, value_]) => {
            if (!FEMALE_BENEFITS.includes(key)) {
                return true;
            }

            return isFem;
        });

        let added = filteredSelected.reduce((before,[key, value_]) => {
            const varx = value_ * 1000;
            const vary = value * varx;
            const varz = vary / 1000;
            if (varz < 0) {
                return before - Math.round(Math.abs(varz) * 100) / 100
            } else {
                return before + Math.round(varz * 100) / 100
            }
        }, 0) ?? 0;


        if ((contextState.organ === "true" || contextState.organ === true)) {
            const varx = contextState.organRider * 1000;
            const vary = varx * value;
            const varz = vary / 1000;
            value = value + Math.round(varz * 100) / 100;
        }

        return value + Number(added);
    }

    const getSelfDependantsFare = () => {
        let values = {
            componentId: contextState?.dependantData?.componentId,
            annual: 0,
            semiannual: 0,
            quarterly: 0,
            monthly: 0,
        };

        if (!quora.fareDependant?.data?.data?.length) {
            return values;
        }
        
        const fareValues = quora.fareDependant?.data?.data[0];
        let travelData = {};
        if (contextState.dependantData.travel) {
            travelData = quora.fareDependantRider?.data?.data[0];
        }
        return {
            componentId: contextState?.dependantData?.componentId,
            annual: calculateFactors(fareValues?.annual ?? 0, contextState.dependantData.travel, userIsFemale(contextState.dependantData.birthday, contextState.dependantData.gender)) + Number(travelData?.annual ?? 0),
            semiannual: calculateFactors(fareValues?.semiannual ?? 0, contextState.dependantData.travel, userIsFemale(contextState.dependantData.birthday, contextState.dependantData.gender)) + Number(travelData?.annual ?? 0),
            quarterly: calculateFactors(fareValues?.quarterly ?? 0, contextState.dependantData.travel, userIsFemale(contextState.dependantData.birthday, contextState.dependantData.gender)) + Number(travelData?.annual ?? 0),
            monthly: calculateFactors(fareValues?.monthly ?? 0, contextState.dependantData.travel, userIsFemale(contextState.dependantData.birthday, contextState.dependantData.gender)) + Number(travelData?.annual ?? 0),
            rider: Number(travelData?.annual ?? 0)
        };

    };

    const getMainFares = (rider = true) => {
        let values = {
            componentId: 9,
            annual: 0,
            semiannual: 0,
            quarterly: 0,
            monthly: 0,
            rider: 0
        };
        if (!quora.fare?.data?.data?.length) {
            return values;
        }
        const fareValues = quora.fare?.data?.data.find((fare) => fare["componentId.id"] == 9);
        let travelData;
        if (contextState.travel) {
            travelData = quora.fareRider?.data?.data.find((fare) => fare["componentId.id"] == 9);
        }

        const isFemale = userIsFemale(contextState.birthday, contextState.gender);
        const riderValue = (rider ? Number(travelData?.annual ?? 0) : 0);
        return {
            componentId: 9,
            annual: calculateFactors(fareValues?.annual ?? 0, contextState.travel, isFemale) + riderValue,
            semiannual: calculateFactors(fareValues?.semiannual ?? 0, contextState.travel, isFemale) + riderValue,
            quarterly: calculateFactors(fareValues?.quarterly ?? 0, contextState.travel, isFemale) + riderValue,
            monthly: calculateFactors(fareValues?.monthly ?? 0, contextState.travel, isFemale) + riderValue,
            rider: Number(travelData?.annual ?? 0)
        };

    };


    const getDependantsFares = (rider = true) => {
        const dependantsFares = [];
        if (!quora.fare?.data?.data?.length) {
            return dependantsFares;
        }
        const dependants = contextState.dependants.filter((dependant) => {
            if (getCurrentStepData().step === 3 && dependant.id == contextState.dependantData.id) return false;
            return true
        })


        for(const dependant of dependants.sort((a,b) => {
            if (a.componentId === 15 && b.componentId !== 15) {
                return -1;
              } else if (b.componentId === 15 && a.componentId !== 15) {
                return 1;
              } else {
                return a.componentId - b.componentId;
              }
        })) {
            dependantsFares.push(getDependantFare(dependant, rider));
        }
        return dependantsFares;
    };

    const getDependantFare = (dependant, rider = true) => {
        const fareValues = quora.fare?.data?.data.find((fare) => {
            const age = fare["minAge"] <= getAge(dependant.birthday) && getAge(dependant.birthday) <= fare['maxAge'];
            const gender = fare["genderId.id"] == dependant.gender;
            const country = fare['rateZoneId.id'] == getCountry(dependant.country, quora.regionDependants?.data?.map(i => i.data));
            return age && gender && country;
        });
        let travelData;
        if (dependant.travel) {
            travelData = quora.fareRider?.data?.data.find((fare) => {
                const age = fare["minAge"] <= getAge(dependant.birthday) && getAge(dependant.birthday) <= fare['maxAge'];
                const gender = fare["genderId.id"] == dependant.gender;
                return age && gender;
            });
        }

        const isFemale = userIsFemale(dependant.birthday, dependant.gender);
        const riderValue = (rider ? Number(travelData?.annual ?? 0) : 0);
        return {
            id: dependant.id,
            componentId: dependant.componentId,
            annual: calculateFactors(fareValues?.annual ?? 0, dependant.travel, isFemale) + riderValue,
            semiannual: calculateFactors(fareValues?.semiannual ?? 0, dependant.travel, isFemale) + riderValue,
            quarterly: calculateFactors(fareValues?.quarterly ?? 0, dependant.travel, isFemale) + riderValue,
            monthly: calculateFactors(fareValues?.monthly ?? 0, dependant.travel, isFemale) + riderValue,
            rider: Number(travelData?.annual ?? 0)
        };
    }

    const calculateValue = (time = "annual", rider = true, useFee = true, forceFee = false) => {
        if (!getCurrentStepData()) return 0;
        let annual = 0;
        if (contextState.addDependant) {
            annual = getDependantsFares(rider).reduce((a,b) => a + Number(b[time]), 0);
        }

        if ((getCurrentStepData().step == 3) && contextState.addDependant) {
            annual += Number(getSelfDependantsFare()[time]);
        }

        const dat = quora?.fee?.data?.data;
        let fee = 0;
        if ((dat?.length && getCurrentStepData().step >= 10) || (dat?.length && forceFee)) {
            fee = dat[0]?.fee;
            fee = Number(fee);
        }
        const value = Number(annual) + Number(getMainFares(rider)[time]) + (useFee ? Number(fee) : 0);
        let val = value * 100;
        val = Math.round(val)
        val = val /100
        return  val;
    }


    useEffect(() => {
        setCalculation(calculateValue() ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quora.fare, quora.fee, contextState.selectedBenefits, quora?.regionDependants?.data, contextState?.travel, getCurrentStepData])


    return {
        getMainFares,
        getDependantsFares,
        getDependantFare,
        calculateFactors,
        calculateValue,
        calculation
    }
}