import mainApi from "./mainApi";
import {useQuery} from "react-query";
import {getAge, getCountry, translateANT} from "../helpers/toolsHelper";
import countriesNames from "i18n-iso-countries";
import { debugBenefits } from "helpers/DebugBenefits";


export const useCountries = () => {
    const query = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
            const {data} = await mainApi.get("/services/quoter-countries");
            return data?.data;
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })

    countriesNames.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countriesNames.registerLocale(require("i18n-iso-countries/langs/es.json"));
    countriesNames.registerLocale(require("i18n-iso-countries/langs/pt.json"));

    const countrySort = (currentLanguage) => {
        return (a, b) => {
            let aval = (countriesNames.getName(a?.isoNum, currentLanguage) ?? a?.description)?.toLowerCase();
            if (a?.isoNum === "530") aval = translateANT(currentLanguage)
            let bval = countriesNames.getName(b?.isoNum, currentLanguage) ?? b?.description
            if (b?.isoNum === "530") bval = translateANT(currentLanguage)
            return aval.localeCompare(bval)
        }
    }

    return {
        ...query,
        getCountryName: countriesNames.getName,
        countrySort
    }
}

export const useBenefits = () => {
    const config = {
        keepPreviousData: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 3
    }

    const structural = useQuery({
        queryKey: ['structural'],
        queryFn: async ({signal}) => {
            const postData = {
                planId: "152",
                optionId: "2607"
            };
            const {data} = await mainApi.post("/services/quoter-structural-benefits", postData, {signal});
            return data;
        },
        ...config
    });

    const regular = useQuery({
        queryKey: ['regular'],
        queryFn: async ({signal}) => {
            const postData = {
                planId: "152",
                optionId: "2607"
            };
            const {data} = await mainApi.post("/services/quoter-regular-benefits", postData, {signal});
            return data;
        },
        ...config
    });


    return {
        regular,
        structural,
        debug: debugBenefits,
        regularData: regular?.data?.data ?? [],
        structuralData: structural?.data?.data ?? []
    };
}

export const getRegionData = async (country, planId) => {
    const postData = {
        "countries": [
            country
        ],
        "planId": planId
    };
    const {data} = await mainApi.post("/services/quoter-regions", postData);
    return data;
}

export const getPlanData = async (planId, coverage) => {
    const postData = {
        "planId": planId,
        "effectiveDate": coverage,
        "terminationDate": coverage
    };
    const {data} = await mainApi.post("/services/quoter-options-plan", postData);
    return data;
}

export const getRiderData = async (coverage) => {
    const postData = {
        "effectiveDate": coverage,
        "terminationDate": coverage
    };

    const {data} = await mainApi.post("/services/quoter-options-rider", postData);
    return data;
}

export const getFeeData = async (optionId, countryISONumber) => {
    const postData = {
        "optionId": Number.isInteger(optionId) ? optionId.toString() : optionId,
        "countryISONumber": countryISONumber ?? -1
    };
    const {data} = await mainApi.post("/services/quoter-options-fee", postData);
    return data;
}

export const getFareData = async (planId, optionId, coverage, age, gender, rateZoneId, componentId = Number(9)) => {
    const postData = {
        "planId": planId,
        "optionId": optionId,
        "effectiveDate": coverage,
        "terminationDate": coverage,
        "fareMembers": [
            {
                "age": Number(age),
                "gender": Number(gender),
                "rateZoneId": rateZoneId,
                "componentId": componentId
            },
        ]
    };
    const {data} = await mainApi.post("/services/quoter-options-fare", postData);
    return data;
}

export const getFareRiderData = async () => {
    
}


export const useQuoter = (context) => {
    const config = {
        keepPreviousData: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 3
    }
    const region = useQuery({
        queryKey: ['region', context?.country, context?.planId],
        queryFn: async ({signal}) => {
            const postData = {
                "countries": [
                    context?.country
                ],
                "planId": context?.planId
            };
            const {data} = await mainApi.post("/services/quoter-regions", postData, {signal});
            return data;
        },
        enabled: !!context?.country && !!context?.planId,
        ...config,
    });

    const regionDependants = useQuery({
        queryKey: ['region', context?.planId, context?.dependants],
        queryFn: async ({signal}) => {
            const promises = [];
            for (const region of context.dependants.map(i => i.country)) {
                const postData = {
                    "countries": [
                        region
                    ],
                    "planId": context?.planId
                };
                const request = mainApi.post("/services/quoter-regions", postData, {signal});
                promises.push(request);
            }
            return await Promise.all(promises);
        },
        enabled: !!context?.planId,
        ...config,
    });

    const plan = useQuery({
        queryKey: ['plan', context?.coverage, context?.planId],
        queryFn: async ({signal}) => {
            const postData = {
                "planId": context?.planId,
                "effectiveDate": context?.coverage,
                "terminationDate": context?.coverage
            };
            const {data} = await mainApi.post("/services/quoter-options-plan", postData, {signal});
            return data;
        },
        enabled: !!context?.coverage && !!context?.planId,
        ...config
    });
    const rider = useQuery({
        queryKey: ['rider', context.coverage],
        queryFn: async ({signal}) => {
            const postData = {
                "effectiveDate": context.coverage,
                "terminationDate": context.coverage
            };

            const {data} = await mainApi.post("/services/quoter-options-rider", postData, {signal});
            return data;
        },
        ...config
    });

    const organRiderQuery = useQuery({
        queryKey: ['rider'],
        queryFn: async ({signal}) => {
            const postData = {
                "effectiveDate": "2023-11-11",
                "terminationDate": "2023-11-11"
            };

            const {data} = await mainApi.post("/services/quoter-options-organ-rider", postData, {signal});
            return data;
        },
        ...config
    });

    const fee = useQuery({
        queryKey: ['fee', region?.data, plan?.data],
        queryFn: async () => {
            const postData = {
                "optionId": Number.isInteger(plan?.data?.data[0]?.id) ? plan?.data?.data[0]?.id.toString() : plan?.data?.data[0]?.id,
                "countryISONumber": !!region.data?.data?.length ? region?.data?.data[0]['stateNumberId.countriesListId.isoNum'] : -1
            };
            const {data} = await mainApi.post("/services/quoter-options-fee", postData);
            return data;
        },
        enabled: !!region.isFetched && !!plan.isFetched,
        ...config
    });
    const fare = useQuery({
        queryKey: [
            'fare',
            context?.addDependant,
            context?.gender,
            context?.coverage,
            context?.birthday,
            plan?.data,
            context?.planId,
            region?.data,
            regionDependants?.data,
            context?.dependants
        ],
        queryFn: async () => {
            const addedDependants = context?.addDependant ? [
                ...context.dependants.map((dependant) => ({
                    "age": Number(getAge(dependant?.birthday)),
                    "gender": Number(dependant?.gender),
                    "rateZoneId": Number(getCountry(dependant.country, regionDependants?.data?.map(i => i.data))),
                    "componentId": Math.abs(Number(dependant?.componentId))
                }))
            ] : [];
            const getOptionId = () => {
                return Number.isInteger(plan?.data?.data[0]?.id) ? plan?.data?.data[0]?.id.toString() : plan?.data?.data[0]?.id;
            }
            const postData = {
                "planId": context?.planId,
                "optionId": !!plan?.data?.data?.length ? getOptionId() : -1,
                "effectiveDate": context?.coverage,
                "terminationDate": context?.coverage,
                "fareMembers": [
                    {
                        "age": Number(getAge(context?.birthday)),
                        "gender": Number(context?.gender),
                        "rateZoneId": !!region?.data?.data?.length ? Number(region?.data?.data[0]?.id) : -1,
                        "componentId": Number(9)
                    },
                    ...addedDependants
                ]
            };
            const {data} = await mainApi.post("/services/quoter-options-fare", postData);
            return data;
        },
        enabled:
            !!context?.coverage
            && !!context?.birthday
            && !!context?.gender
            && !!region.isFetched
            && !!plan.isFetched
            && !!context?.planId,
        ...config
    });

    const fareDependant = useQuery({
        queryKey: [
            'fareDependant',
            context?.addDependant,
            context?.dependantData.gender,
            context?.coverage,
            context?.dependantData.componentId,
            context?.dependantData.birthday,
            context?.dependantData.country,
            plan?.data,
            context?.planId
        ],
        queryFn: async ({signal}) => {
            const getOptionId = () => {
                return Number.isInteger(plan?.data?.data[0]?.id) ? plan?.data?.data[0]?.id.toString() : plan?.data?.data[0]?.id;
            }

            const postData2 = {
                "countries": [
                    context?.dependantData.country
                ],
                "planId": context?.planId
            };
            const {data: dataRegion} = await mainApi.post("/services/quoter-regions", postData2, {signal});
            
            const postData = {
                "planId": context?.planId,
                "optionId": !!plan?.data?.data?.length ? getOptionId() : -1,
                "effectiveDate": context?.coverage,
                "terminationDate": context?.coverage,
                "fareMembers": [
                    {
                        "age": Number(getAge(context?.dependantData.birthday)),
                        "gender": Number(context?.dependantData.gender),
                        "rateZoneId": !!dataRegion?.data?.length ? Number(dataRegion?.data[0]?.id) : -1,
                        "componentId": Math.abs(Number(context?.dependantData.componentId))
                    }
                ]
            };
            const {data} = await mainApi.post("/services/quoter-options-fare", postData, {signal});
            return data;
        },
        enabled:
            !!context?.coverage
            && !!context?.dependantData.birthday
            && !!context?.dependantData.gender
            && !! context?.dependantData.country
            && !!plan.isFetched
            && !!context?.planId
            && !!context?.dependantData.componentId,
        ...config
    });

    const fareRider = useQuery({
        queryKey: [
            'fareRider',
            rider.data,
            context?.addDependant,
            context?.gender,
            context?.coverage,
            context?.birthday,
            plan?.data, context?.planId,
            regionDependants?.data,
            context?.dependants
        ],
        queryFn: async () => {
            const addedDependants = context?.addDependant ? [
                ...context.dependants.map((dependant) => ({
                    "age": Number(getAge(dependant?.birthday)),
                    "gender": Number(dependant?.gender),
                    "rateZoneId": 1067,
                    "componentId": Number(dependant?.componentId)
                }))
            ] : [];
            const postData = {
                "planId": 267,
                "optionId": Number.isInteger(rider?.data?.data[0]?.id) ? rider?.data?.data[0]?.id.toString() : rider?.data?.data[0]?.id,
                "effectiveDate": context?.coverage,
                "terminationDate": context?.coverage,
                "fareMembers": [
                    {
                        "age": Number(getAge(context?.birthday)),
                        "gender": Number(context?.gender),
                        "rateZoneId": 1067,
                        "componentId": Number(9)
                    },
                    ...addedDependants
                ]
            };
            const {data} = await mainApi.post("/services/quoter-options-fare", postData);
            return data;
        },
        enabled:
            !!rider?.data?.data
            && !!context?.coverage
            && !!context?.birthday
            && !!context?.gender
            && !!region.isFetched
            && !!plan.isFetched
            && !!context?.planId,
        ...config
    });

    const fareDependantRider = useQuery({
        queryKey: [
            'fareDependantRider',
            rider.data,
            context?.addDependant,
            context?.coverage,
            plan?.data, 
            context?.planId,
            context?.dependantData.gender,
            context?.coverage,
            context?.dependantData.componentId,
            context?.dependantData.birthday,
            context?.dependantData.country,
        ],
        queryFn: async () => {
            const riderId = rider?.data?.data[0]?.id;
            const postData = {
                "planId": 267,
                "optionId": Number.isInteger(riderId) ? riderId.toString() : riderId,
                "effectiveDate": context?.coverage,
                "terminationDate": context?.coverage,
                "fareMembers": [
                    {
                        "age": Number(getAge(context?.dependantData.birthday)),
                        "gender": Number(context?.dependantData.gender),
                        "rateZoneId": 1067,
                        "componentId": Math.abs(Number(context?.dependantData.componentId))
                    }
                ]
            };
            const {data} = await mainApi.post("/services/quoter-options-fare", postData);
            return data;
        },
        enabled:
            !!rider?.data?.data
            && !!context?.coverage
            && !!context?.dependantData.birthday
            && !!context?.dependantData.gender
            && !!context?.dependantData.componentId
            && !!plan.isFetched
            && !!context?.planId,
        ...config
    });


    return {
        region,
        regionDependants,
        plan,
        fareRider,
        organRiderQuery,
        fareDependantRider,
        rider,
        fee,
        fare,
        fareDependant
    };

}

