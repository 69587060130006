/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, {useContext, createContext, useEffect, useState} from "react";
import {useBenefits, useQuoter} from "../api/quoterApi";
import {arrayIncludesNumber, boolIsTrue, extractNumberFromString, getAge, valusIsTrue} from "../helpers/toolsHelper";
import {useDate} from "../hooks/cotizador/DateHook";
import { benefitOptions } from "helpers/BenefitOptionsValues";
import { getEnvVariables } from "helpers/getEnvVariables";


const ApiContext = createContext();

const CHANGE_DATA = "cotizador-change_data";
const CHANGE_DEPENDANT_DATA = "cotizador-change_dependant_data";
const RESET_DEPENDANT_DATA = "cotizador-reset_dependant_data";
const ADD_DEPENDANT = "cotizador-add_dependant";
const REMOVE_DEPENDANT = "cotizador-remove_dependant";
const EDIT_DEPENDANT = "cotizador-edit_dependant";
const START_EDIT_DEPENDANT = "cotizador-start_edit_dependant";
const CHANGE_BENEFIT = "cotizador-change_benefit";
const CHANGE_BENEFIT_NAME = "cotizador-change_benefit_name";
const CHANGE_BENEFIT_ID = "cotizador-change_benefit_id";
const RESET_DATA = "cotizador-reset_data";
const LOAD_DATA = "cotizador-load_data";
const EDIT_DEPENDANT_TRAVEL = "cotizador-edit_dependant--travel";

export const FEMALE_BENEFITS = [
    // "Maternity and cesarean", 
    "Maternity and newborn complications"
];
export const OUTPATION_BENEFITS = [
    "Complementary therapy: chiropractor, psychologist, psychiatrist, osteopathy and/or acupuncture",
    // "Prescribed medications",
    // "Mental health prescription medication",
    // "Refractive eye surgery",
    "Maximum outpatient coverage per insured, per policy year"
];

const preferreds = [
    100,
    // 148,
     145, 164, 215, 158, 111, 128,
    // 106,
    105,
    // 63,
    65, 40, 47, 74, 52, 57, 89,
    // 94,
    // 181, 214,
    // 167,
    205, 187,
    96,
    171,
    212,
    178,
    2214
];


const initialState = {
    step: 0,
    total: 0,
    name: "",
    surname: "",
    email: "",
    gender: 0,
    country: 0,
    coverage: "",
    coverageShow: "",
    planId: "152",
    birthday: "",
    birthdayShow: "",
    travel: false,
    addDependant: false,
    selectedBenefits: [],
    selectedBenefitsNames: [],
    selectedBenefitsIds: [],
    dependants: [],
    maximun: false,
    organ: true,
    organRider: 0,
    organId: 0,
    outpationBenefitsEnabled: false,
    outpationBenefitsEnabled4fases:false,
    outpationBenefitsmaternidad: false,
    step14Confirmation: false,
    step15Confirmation: false,
    organRiderName: "No",
    timely: "annual",
    acceptNonRegion: false,
    acceptNonRegion2: false,
    limitedCover: false,
    nonAmbulance: false,
    nonMaternidad:false,
    dependantData: {
        name: "",
        surname: "",
        email: "",
        gender: 0,
        country: 0,
        birthday: "",
        birthdayShow: "",
        componentId: "",
        travel: false
    }
};

const cotizador = (state, action) => {
    switch (action.type) {
        case LOAD_DATA:
            return action.payload;
        case CHANGE_DATA:
            return {...state, [action.payload.name]: action.payload.value};
        case CHANGE_DEPENDANT_DATA:
            return {...state, dependantData: {...state.dependantData, [action.payload.name]: action.payload.value}};
        case ADD_DEPENDANT:
            state.dependantData.id = state.dependants.length;
            return {...state, dependants: [...state.dependants, state.dependantData]};
        case REMOVE_DEPENDANT:
            return {
                ...state,
                dependants: state.dependants
                    .filter(i => i.id !== action.payload)
                    .map((i, index) => ({...i, id: index}))
            };
        case EDIT_DEPENDANT:
            return {...state, dependants: state.dependants.map(i => {
                if (i.id === state.dependantData.id) return state.dependantData;
                return i;
                })};
        case EDIT_DEPENDANT_TRAVEL:
            return {...state, dependants: state.dependants.map(i => {
                if (i.id === action.payload.id) return {...i, travel: action.payload.travel};
                return i;
                })};
        case START_EDIT_DEPENDANT:
            return {...state, dependantData: state.dependants.find(i => i.id === action.payload)};
        case RESET_DEPENDANT_DATA:
            return {...state, dependantData: initialState.dependantData};
        case CHANGE_BENEFIT:
            return {...state, selectedBenefits: {...state.selectedBenefits, [action.payload.name]: action.payload.value}};
        case CHANGE_BENEFIT_NAME:
            return {...state, selectedBenefitsNames: {...state.selectedBenefitsNames, [action.payload.name]: action.payload.value}};
        case CHANGE_BENEFIT_ID:
            return {...state, selectedBenefitsIds: {...state.selectedBenefitsIds, [action.payload.name]: action.payload.value}};
        case RESET_DATA:
            return initialState;
        default:
            return state;
    }
};

export function useCotizador() {
    return useContext(ApiContext);
}

export function ApiProvider({ children }) {
    const [contextState, dispatch] = React.useReducer(cotizador, initialState);
    const [errors, setErrors] = React.useState({});
    const [benefitErrors, setBenefitErrors] = React.useState({});
    const [maximun, setMaximun] = React.useState(0);
    // eslint-disable-next-line no-unused-vars
    const [maximunOutpatient, setMaximunOutpatient] = React.useState(0);
    const [calculation, setCalculation] = useState(0);
    const quora = useQuoter(contextState);
    const [show, setShow] = useState(false);
    const [ignoredBenefits, setIgnoredBenefits] = useState([]);
    const benefits = useBenefits();
    const {DEBUG} = getEnvVariables();

    useEffect(() =>{
        if (localStorage.getItem('coContext') && DEBUG) {
            dispatch({type: LOAD_DATA, payload: JSON.parse(localStorage.getItem('coContext'))})
        }
    }, [])

    useEffect(() =>{
        if (DEBUG) {
            localStorage.setItem("coContext", JSON.stringify(contextState));
        }
    }, [contextState])

    const isEverythingLoading = () => {
        const {
            fare,
            fareRider,
            fee,
            rider,
            plan,
            region,
            organRiderQuery,
        } = quora;

        const {
            regular,
            structural
        } = benefits;

        if (
            fare.isLoading ||
            fareRider.isLoading ||
            fee.isLoading ||
            rider.isLoading ||
            plan.isLoading ||
            region.isLoading ||
            organRiderQuery.isLoading ||
            regular.isLoading ||
            structural.isLoading
        ) {
            return true;
        }

        if (
            fare.error ||
            fareRider.error ||
            fee.error ||
            rider.error ||
            plan.error ||
            region.error ||
            organRiderQuery.error ||
            regular.error ||
            structural.error
        ) {
            return true;
        }

        return false;

    }
    
    const isFemale = () => {
        const userIsFemale = 18 <= getAge(contextState.birthday) && getAge(contextState.birthday) <= 43 && contextState.gender == 2;
        const dependantIsFemale = contextState.dependants.find((dependant) => 18 <= getAge(dependant.birthday) && getAge(dependant.birthday) <= 43 && dependant.gender == 2);
        
        return userIsFemale || (dependantIsFemale && boolIsTrue(contextState.addDependant));
    }

    useEffect(() => {
        calculateIgnored();
    }, [contextState.selectedBenefitsIds, contextState.dependants, contextState.country]);

    useEffect(() => {
        calculateSelected();
    }, [ignoredBenefits]);

    const calculateIgnored = (maximun_ = 0, maximunOutpatient_ = 0) => {
        const ignored = [];

        benefitOptions.forEach(i => {
            let proceed = false;
            if(i.ignored instanceof Function) {
                proceed = i.ignored(contextState);
            } else {
                proceed = i.ignored;
            }
            if (proceed) {
                ignored.push(i.id);
            }
        })

        if (maximunOutpatient_) {
            ignored.push(176);
        }
        
        setIgnoredBenefits(ignored);
    }

    const isPreferred = (id) => {

        return preferreds.includes(id);
      };

    const calculateSelectedItem = (benefit_, allBenefits) => {
        const benefit =  benefit_["factorId.nameFactor"];
        const preferred = allBenefits.filter((i) => i["factorId.nameFactor"] === benefit)
                                    .filter((i) => !ignoredBenefits.includes(i.id))
                                    .find((i) => isPreferred(i.id));
        let selected = null;
        if (preferred) selected = preferred;
        if (!preferred) selected = !ignoredBenefits.includes(benefit_.id) && benefit_;
        
        const value = selected?.factor;
    
        if (value) {
            const name = benefit;
            handleOnChangeBenefit({ target: { value, name } }, {text: selected?.description, id: selected?.id});
        }
    }

    const getAllBenefits = () => {
        return  [...benefits.structuralData, ...benefits.regularData];
    }

    const calculateSelected = () => {
        const allBenefits = getAllBenefits();
        allBenefits.forEach((benefit_) => {
            const benefit =  benefit_["factorId.nameFactor"];
            if (!contextState.selectedBenefits[benefit]) return;
            if (arrayIncludesNumber(ignoredBenefits, contextState.selectedBenefitsIds[benefit])) {
                calculateSelectedItem(benefit_, allBenefits)
            }
        })
    }

    const changeDataValue = (name, value) => {
        dispatch({type: CHANGE_DATA, payload: {name, value}});
    }

    const changeBenefitValue = (name, value) => {
        dispatch({type: CHANGE_BENEFIT, payload: {name, value}});
    }

    const changeBenefitNameValue = (name, value) => {
        dispatch({type: CHANGE_BENEFIT_NAME, payload: {name, value}});
    }

    const changeBenefitIdValue = (name, value) => {
        dispatch({type: CHANGE_BENEFIT_ID, payload: {name, value}});
    }

    const changeDependantValue = (name, value) => {
        dispatch({type: CHANGE_DEPENDANT_DATA, payload: {name, value}});
    }

    const addDependantToList = () => {
        dispatch({type: ADD_DEPENDANT});
    }

    const resetDependantData = () => {
        setErrors({...errors, dependants: {}});
        dispatch({type: RESET_DEPENDANT_DATA});
        dateHandler.setDates({...dateHandler.dates, dependantB: ""})
    }

    const resetData = () => {
        dateHandler.resetDates();
        dispatch({type: RESET_DATA});
    }

    const deleteDependant = (dependantId) => {
        dispatch({type: REMOVE_DEPENDANT, payload: dependantId});
    }

    const startEdit = (dependantId) => {
        dispatch({type: START_EDIT_DEPENDANT, payload: dependantId});
        dateHandler.setDates({...dateHandler.dates, dependantB: new Date(contextState.dependants.find(i => i.id == dependantId).birthday)})
    }

    const editDependant = () => {
        dispatch({type: EDIT_DEPENDANT});
    }

    
    const editDependantTravel = (id, travel) => {
        dispatch({type: EDIT_DEPENDANT_TRAVEL, payload: {id, travel}});
    }

    const handleOnChange = async (e, meta = null) => {
        const name = e?.target?.name ?? meta?.name;
        const value = e?.target?.value ?? e?.value;
        await changeDataValue(name, value);
    }

    const handleOnChangeDependant = async (e, meta = null) => {
        const name = e?.target?.name ?? meta?.name;
        const value = e?.target?.value ?? e?.value;
        await changeDependantValue(name, value);
    }

    const handleOnChangeBenefit = async (e, meta = null) => {
        const name = e?.target?.name ?? meta?.name;
        const value = e?.target?.value ?? e?.value;
        let text =  meta?.text
        let id =  meta?.id;
        if (e?.target?.options?.length) {
            text =  e.target?.options[e.target.selectedIndex]?.dataset?.description ?? meta?.text;
            id =  e.target?.options[e.target.selectedIndex]?.dataset?.id ?? meta?.id;
        }
       
        changeBenefitValue(name, value);
        changeBenefitNameValue(name, text);
        changeBenefitIdValue(name, id);

    }

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    const thereIsTravel = () => {
        return [valusIsTrue(contextState.travel), ...contextState.dependants.map(i => valusIsTrue(i.travel))].some(i => !!i);
    }

    const dateHandler = useDate(changeDependantValue, changeDataValue);


   

    const value = {
        ...contextState,
        ...quora,
        ...benefits,
        contextState,
        handleChecks,
        isFemale,
        changeDataValue,
        resetData,
        handleOnChange,
        handleOnChangeBenefit,
        addDependantToList,
        changeDependantValue,
        handleOnChangeDependant,
        resetDependantData,
        editDependant,
        editDependantTravel,
        deleteDependant,
        startEdit,
        thereIsTravel,
        quora,
        benefits,
        calculation,
        setCalculation,
        errors,
        setErrors,
        show, 
        setShow,
        ignoredBenefits,
        setIgnoredBenefits,
        ...dateHandler,
        maximun,
        isEverythingLoading,
        benefitErrors,
        setBenefitErrors,
        calculateSelectedItem,
        preferreds,
        getAllBenefits
    };


    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
