import { TitleH1Component } from "components/TitleH1Component";
import { TitleH2Component } from "components/TitleH2Component";
import React, { Fragment, useContext, useState, useEffect } from "react";
import Stylegeneral from '../../scss/general.module.scss';
import { TextsContext } from "context/textsContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styleForm from '../../scss/form.module.scss';
import { Icon } from "@iconify/react";
import SelectBenefitsComponent from "components/SelectBenefitsComponent";
import { useCotizador } from "context/apiContext";
const Step15Page = () => {
    const { benefits } = useCotizador();
    const { texts } = useContext(TextsContext);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const paragraphgeneral_header_title = texts?.general_header_title;
    const general_header_title_marca = paragraphgeneral_header_title?.replace?.("®", `<span>&reg;</span>`) ?? '';

    useEffect(() => {

        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container pb-4">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            <p dangerouslySetInnerHTML={{ __html: general_header_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>

                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`mb-0 raleway_semibold fw_700  lh_30 color_primary pb-2 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title_anexos ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 pb-5`}>
                        <div className={`col-12 col-md-6 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step15_form_field_periodo ?? 'Cirugía refractiva (período de espera de 24 meses)'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'top' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_surgery ?? 'Preventive medical examinations conducted by a certified physician and/or an institution providing medical services.'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>
                                <SelectBenefitsComponent benefits={benefits.debug} benefit={"Refractive eye surgery"} className={`${styleForm.customselectLine}`} />

                               

                                {/* <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Preventive health checkup"} className={`${styleForm.customselectLine}`} /> */}

                            </div>
                        </div>
                        <div className={`col-12 col-md-6 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step15_form_cirugia_bariatrica ?? 'Cirugía bariátrica y/o medicamentos para la pérdida de peso (período de espera de 24 meses, con deducible y coaseguro correspondiente en caso de aplicar)'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'top' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step15_tooltip_cirugia_bariatrica ?? 'Preventive medical examinations conducted by a certified physician and/or an institution providing medical services.'}</Tooltip>}
                                    >
                                        <div className={`${styleForm.iconselect} ms-1`}>
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <SelectBenefitsComponent benefits={benefits.debug} benefit={"Bariatric surgery and/or weight loss medication"} className={`${styleForm.customselectLine}`}  />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Step15Page;