/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useContext } from "react";
import { TextsContext } from '../context/textsContext';
import { useCotizador } from "../context/apiContext";
import { getAge, getAgeInDays, getDiffDays } from "../helpers/toolsHelper";
import { useSteps } from "./useSteps";
import {useBenefitValidation} from "./cotizador/BenefitValidationHook";


export const useValidation = () => {
	const { texts } = useContext(TextsContext);
    const { errors, setErrors, contextState, quora, dates, selectedBenefits, selectedBenefitsIds} = useCotizador();
    const {getCurrentStepData, benefitsGenerator} = useSteps();
    const validation = useBenefitValidation(selectedBenefits);


    const validateBenefits = (clean = false) => {
        const list = validation.getBenefitsThatAreNot([...benefitsGenerator(getCurrentStepData?.()?.step)]);
        const newErrors = {benefitsList: {}};
        [...benefitsGenerator(getCurrentStepData?.()?.step)].forEach(i => {
            let mainError = null;
            if ( errors["benefitsList"] !== undefined && errors["benefitsList"][i] !== undefined ) mainError = errors["benefitsList"][i];
            let blank = null;
            if (mainError) blank = mainError['blank'];

            if (newErrors["benefitsList"][i] === undefined) {
                newErrors["benefitsList"][i] = mainError ?? {};
            }

            if (clean) {
                if (blank)
                    newErrors["benefitsList"][i]["blank"] = list.includes(i) ? texts?.general_field_validation_required ?? 'This field is required' : null;
            } else {
                newErrors["benefitsList"][i]["blank"] = list.includes(i) ? texts?.general_field_validation_required ?? 'This field is required' : null;
            }
        })

        return newErrors;
    }

    const createInvalidError = (list, key, conditional = false, message = null, clean = false) => {
        message = message ? message : texts?.general_field_validation_required ?? 'This field is required';
        if (list[key] === undefined) {
            list[key] = errors[key] ?? {};
        }
        if (clean) {
            if (errors[key] && errors[key]['invalid']) {
                list[key]['invalid'] = conditional ? message : null;
            }
        } else {
            list[key]['invalid'] = conditional ? message : null;
        }
    }

    const validateExceptions = (clean = false) => {
        const newErrors = {};
        const step = typeof getCurrentStepData?.()?.step === 'string' ? Number(getCurrentStepData?.()?.step) : getCurrentStepData?.()?.step;
        const createInvalidErrorWrapper = (key, conditional, message = null) => {
            createInvalidError(newErrors, key, conditional, message, clean);
        }

        if (step >= 5) {
            createInvalidErrorWrapper( "organ", contextState.organ && contextState.organRider == 0);
        }


        if (step === 9) {
            createInvalidErrorWrapper( "acceptNonRegion", !contextState.acceptNonRegion);
            createInvalidErrorWrapper( "acceptNonRegion2", !contextState.acceptNonRegion2);
        }

        if (step === 4) {
            createInvalidErrorWrapper( "limitedCover", !contextState.limitedCover);
        };

        if (step === 6) {
            createInvalidErrorWrapper( "nonAmbulance", !contextState.nonAmbulance);
        };

        if (step === 8) {
            const maternityIsNo = selectedBenefitsIds['Maternity and cesarean'] === "182";
            const newbornIsNo = selectedBenefitsIds['Maternity and newborn complications'] === '202';
            createInvalidErrorWrapper( "nonMaternidad", (!contextState.nonMaternidad && !contextState.outpationBenefitsmaternidad) || (!contextState.nonMaternidad && (maternityIsNo || newbornIsNo)));
        };

        if (step === 2) {
            createInvalidErrorWrapper( "dependantsTable", contextState?.dependants.length === 0 && contextState?.addDependant);
        };

        return newErrors;
    }

    const validateValues = () => {
        const keys = [
            "name",
            "surname",
            "email",
            "gender",
            "country",
            "coverage",
            "birthday",
        ];
        const newErrors = {};
        keys.forEach((i) => {
            if (newErrors[i] === undefined) {
                newErrors[i] = errors[i] ?? {};
            }
            newErrors[i]['empty'] = !contextState[i] ? texts?.general_field_validation_required ?? 'This field is required' : null;
        })

        const pattern = new RegExp(/[\d+]/i);

        newErrors['name']['invalid'] = pattern.test(contextState.name) ? texts?.general_field_validation_name ?? 'Name should not contain numbers' : null;
        newErrors['surname']['invalid'] = pattern.test(contextState.surname) ? texts?.general_field_validation_last_name ?? 'Last name should not contain numbers' : null;
        newErrors['email']['invalid'] = !validateEmail(contextState.email) ? texts?.general_field_validation_email ?? 'Please introduce valid email' : null;
        newErrors['birthday']['invalid'] = contextState.birthday && getAge(contextState.birthday) < 18 ? texts?.general_field_validation_age ?? 'You cannot be less than 18 years old.' : null;
        newErrors['coverage']['invalid'] = (contextState.coverage && (45 < getDiffDays(new Date(), dates.coverage) || getDiffDays(new Date(), dates.coverage) < -15))  ? texts?.general_field_coverage ?? 'Coverage must start either 15 before or 45 days after today' : null;


        setErrors({
            ...errors,
            ...newErrors,
            ...validateExceptions(),
            ...validateBenefits(),
        });

        return !!Object.values({
            ...errors,
            ...newErrors
        }).map((i) => Object.values(i).every(a => a)).filter(i => i).length;
    }

    const validateDependantValues = () => {
        const keys = [
            "name",
            "surname",
            "gender",
            "country",
            "birthday",
            "componentId",
        ];
        const newErrors = {dependants: {}};
        const currentErrors = errors['dependants'] ?? {};
        keys.forEach((i) => {
            if (newErrors['dependants'][i] === undefined) {
                newErrors['dependants'][i] = currentErrors[i] ?? {};
            }
            newErrors['dependants'][i]['empty'] = !contextState['dependantData'][i] ? texts?.general_field_validation_required ?? 'This field is required' : null;
        })
        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) > 23 && contextState.dependantData.componentId == 16) {
            newErrors['dependants']["birthday"]['invalidInputSon'] = texts?.general_field_validation_dependent_age ?? 'The age of the dependent cannot be older than 23 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputSon'] = null;
        }

        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) > 23 && contextState.dependantData.componentId == -16) {
            newErrors['dependants']["birthday"]['invalidInputOther'] = texts?.general_field_validation_dependent_age ?? 'The age of the dependent cannot be older than 23 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputOther'] = null;
        }

        if (contextState.dependantData.birthday && getAgeInDays(contextState.dependantData.birthday) < 30 && (contextState.dependantData.componentId == -16 || contextState.dependantData.componentId == 16)) {
            newErrors['dependants']["birthday"]['invalidInputOther'] = texts?.general_field_validation_dependent_age_day ?? 'The dependent should be at least 30 days old.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputOther'] = null;
        }

        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) < 18 && contextState.dependantData.componentId == 15) {
            newErrors['dependants']["birthday"]['invalidInputSpouse'] = texts?.general_field_validation_spouse_age ?? 'The age of your partner cannot be younger than 18 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputSpouse'] = null;
        }

        const pattern = new RegExp(/[\d+]/i);

        newErrors['dependants']['name']['invalid'] = pattern.test(contextState.dependantData.name) ? texts?.general_field_validation_name ?? 'Name should not contain numbers' : null;
        newErrors['dependants']['surname']['invalid'] = pattern.test(contextState.dependantData.surname) ? texts?.general_field_validation_last_name ?? 'Last name should not contain numbers' : null;
        newErrors['dependants']['componentId']['invalid'] = contextState.dependants.some(item => item.componentId == 15) && contextState.dependantData?.id != contextState.dependants.find(item => item.componentId == 15).id && contextState.dependantData.componentId == 15 ? texts?.general_field_validation_spouse ?? 'You can only select one spouse' : null;

        setErrors({
            ...errors,
            ...newErrors
        });

        return !!Object.values(newErrors['dependants']).map((i) => Object.values(i).reduce((a,b) => {
            if (a) return true;
            if (!b) return false;
            return b;
        }, false)).filter(i => i).length;
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    const validateStep1 = () => {
        const keys = [
            "name",
            "surname",
            "email",
            "gender",
            "country",
            "coverage",
            "birthday",
        ];
        const newErrors = {};

        if (newErrors["dependantsTable"] === undefined) {
            newErrors["dependantsTable"] = errors["dependantsTable"] ?? {};
        }
        newErrors['dependantsTable']['invalid'] = (contextState?.dependants.length === 0 && contextState?.addDependant) ? '[hidden]' : null;


        if (getCurrentStepData?.()?.step !== 1) return;
        keys.forEach((i) => {
            if (newErrors[i] === undefined) {
                newErrors[i] = errors[i] ?? {};
            }
            if (newErrors[i]['empty'] && contextState[i]) newErrors[i]['empty'] = null;
        })

        const pattern = new RegExp(/[\d+]/i);

        if (contextState.name) {
            newErrors['name']['invalid'] = pattern.test(contextState.name) ? texts?.general_field_validation_name ?? 'Name should not contain numbers' : null;
        }
        if (contextState.surname) {
            newErrors['surname']['invalid'] = pattern.test(contextState.surname) ? texts?.general_field_validation_last_name ?? 'Last name should not contain numbers' : null;
        }
        if ( contextState.email) {
            newErrors['email']['invalid'] = !validateEmail(contextState.email) ? texts?.general_field_validation_email ?? 'Please introduce valid email' : null;
        }
        if (contextState.birthday) {
            newErrors['birthday']['invalid'] = contextState.birthday && getAge(contextState.birthday) < 18 ? texts?.general_field_validation_age ?? 'You cannot be less than 18 years old.' : null;
        }
        if (dates.coverage) {
            newErrors['coverage']['invalid'] = (dates.coverage && (45 < getDiffDays(new Date(), dates.coverage) || getDiffDays(new Date(), dates.coverage) < -15))  ? texts?.general_field_coverage ?? 'Coverage must start either 15 before or 45 days after today' : null;
        }

        return newErrors;
    }

    const validateStep3 = () => {
        const keys = [
            "name",
            "surname",
            "gender",
            "country",
            "birthday",
            "componentId",
        ];
        const newErrors = {dependants: {}};
        const currentErrors = errors['dependants'] ?? {};
        keys.forEach((i) => {
            if (newErrors['dependants'][i] === undefined) {
                newErrors['dependants'][i] = currentErrors[i] ?? {};
            }
            if (newErrors['dependants'][i]['empty'] && contextState['dependantData'][i]) newErrors['dependants'][i]['empty'] = null;
        })

        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) > 23 && contextState.dependantData.componentId == 16) {
            newErrors['dependants']["birthday"]['invalidInputSon'] = texts?.general_field_validation_dependent_age ?? 'The age of the dependent cannot be older than 23 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputSon'] = null;
        }

        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) > 23 && contextState.dependantData.componentId == -16) {
            newErrors['dependants']["birthday"]['invalidInputOther'] = texts?.general_field_validation_dependent_age ?? 'The age of the dependent cannot be older than 23 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputOther'] = null;
        }

        if (contextState.dependantData.birthday && getAgeInDays(contextState.dependantData.birthday) < 30 && (contextState.dependantData.componentId == -16 || contextState.dependantData.componentId == 16)) {
            newErrors['dependants']["birthday"]['invalidInputOther'] = texts?.general_field_validation_dependent_days ?? 'The dependant should be at least 30 days old.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputOther'] = null;
        }

        if (contextState.dependantData.birthday && getAge(contextState.dependantData.birthday) < 18 && contextState.dependantData.componentId == 15) {
            newErrors['dependants']["birthday"]['invalidInputSpouse'] = texts?.general_field_validation_spouse_age ?? 'The age of your partner cannot be younger than 18 years.';
        } else {
            newErrors['dependants']["birthday"]['invalidInputSpouse'] = null;
        }

        const pattern = new RegExp(/[\d+]/i);

        if (contextState.dependantData.name)
            newErrors['dependants']['name']['invalid'] = pattern.test(contextState.dependantData.name) ? texts?.general_field_validation_name ?? 'Name should not contain numbers' : null;
        if (contextState.dependantData.surname)
            newErrors['dependants']['surname']['invalid'] = pattern.test(contextState.dependantData.surname) ? texts?.general_field_validation_last_name ?? 'Last name should not contain numbers' : null;
        if (contextState.dependantData.componentId)
            newErrors['dependants']['componentId']['invalid'] = contextState.dependants.some(item => item.componentId == 15) && contextState.dependantData?.id != contextState.dependants.find(item => item.componentId == 15).id && contextState.dependantData.componentId == 15 ? texts?.general_field_validation_spouse ?? 'You can only select one spouse' : null;

        return newErrors;
    }

    useEffect(() => {
        let newErrors = {}
        if (getCurrentStepData?.()?.step === 1) newErrors = {...validateStep1()};
        if (getCurrentStepData?.()?.step === 3) newErrors = {...validateStep3()};

        setErrors({
            ...errors,
            ...newErrors,
            ...validateExceptions(true),
            ...validateBenefits(true)
        });
    }, [contextState])


    useEffect(() => {

    }, []);


    
    useEffect(() => {

        setErrors({
            ...errors,
            coverage: {
                ...(errors.coverage ?? {}),
                // noCover: (quora?.plan?.isLoading || (!quora?.plan?.data?.data?.length && contextState.coverage)) ? texts?.general_field_validation_date ?? "This date doesn't have coverage" : null,
                fareError: quora?.fare.isLoaded && ((!quora?.fare?.data?.data?.length && contextState.coverage)) ? texts?.general_field_validation_date ?? "This date doesn't have coverage" : null,
            },
        });

    }, [quora.region?.data, quora.plan?.data, quora?.regionDependants?.data]);

    const validateBirthday = (date_) => {
        const date = new Date(date_);
        if (!date_) return true;
        if ((new Date()).getTime() < date.getTime()) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (validateBirthday(contextState.birthday)) {
            return
        }
        setErrors({
            ...errors,
            birthday: {...(errors.birthday ?? {}), badEntry: texts?.general_field_validation_birthday ?? "Birthday shouldn't be in the future"}
        });
        setTimeout(() => {
            setErrors({
                ...errors,
                birthday: {...(errors.birthday ?? {}), badEntry: null}
            });
        }, 1000 * 5)
        
    }, [contextState.birthday]);



    return {
        validateValues,
        validateDependantValues,
    }
}