import React from "react";
import MainLayout from "../layouts/MainLayout";
import { useSteps } from "../hooks/useSteps";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { useDataParams } from "hooks/DataParamsHook";

export const CotizadorGuard = ({ element, path }) => {
  const { user, loading } = useAuth();
  const { inSteps, getSteps } = useSteps();
  const {loading: loadingParams} = useDataParams();

  return (
    <MainLayout>
      {(user) ? (
        <>
          {inSteps(path) ? (
            element
          ) : (
            <Navigate to={getSteps[getSteps?.length - 1]?.path ?? "/step1"} />
          )}
        </>
      ) : (
        <>{loading === false && loadingParams === false && <Navigate to={"/login"} />}</>
      )}
    </MainLayout>
  );
};
