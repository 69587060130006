import { useCotizador } from "context/apiContext";
import { TextsContext } from "context/textsContext";
import { arrayIncludesNumber, extractNumberFromString } from "helpers/toolsHelper";
import React, { useContext } from "react";

export const OrganOptions = ({ isOpen }) => {
  const { texts } = useContext(TextsContext);
  const { organRider, organRiderQuery, ignoredBenefits } = useCotizador();

  const getOrganList = () => {
    if (!(typeof organRiderQuery?.data?.data?.sort === "function")) return [];

    /** @type {array} list */
    let list = organRiderQuery?.data?.data;
    list = list.filter(item => {
        if (arrayIncludesNumber(ignoredBenefits, item.id)) {
            return false;
        }
        return true;
    });

    return list.sort(
      (a, b) =>
        extractNumberFromString(b?.description) -
        extractNumberFromString(a?.description)
    );
  };

  return (
    <>
      <option value={0} disabled={true}>
        {texts?.general_form_field_select ?? "Select"}
      </option>

      {getOrganList().map((item) => (
        <option
          key={item.id}
          value={item.factor}
          data-description={item.description}
          data-id={item.id}
        >
          <span>{item.description}</span>
          {item.id === 36 &&
            isOpen &&
            (organRider !== item.factor || isOpen) && (
              <span></span>
            )}{" "}
        </option>
      ))}
    </>
  );
};
