import { getCountryGroup, GREAT, LATAM } from "./CountryGroups";
import { arrayIncludesNumber } from "./toolsHelper";

/**
 * @typedef {Object} BenefitOption
 * @property {Number} id
 * @property {(contextState) => boolean|boolean} ignored
 */

/**
 * @type {BenefitOption[]}
 */
export const benefitOptions = [
  {
    id: 78,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        47
      );
    },
  },
  {
    id: 79,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        47
      );
    },
  },
  {
    id: 77,
    ignored: true,
  },
  {
    id: 75,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        44
      );
    },
  },
  {
    id: 76,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        44
      );
    },
  },
  {
    id: 101,
    ignored: true,
  },
  {
    id: 34,
    ignored: true,
  },
  {
    id: 129,
    ignored: true,
  },
  {
    id: 126,
    ignored: true,
  },
  {
    id: 81,
    ignored: true,
  },
  {
    id: 97,
    ignored: true,
  },
  {
    id: 150,
    ignored: true,
  },
  {
    id: 210,
    ignored: true,
  },
  {
    id: 149,
    ignored: true,
  },
  {
    id: 216,
    ignored: true,
  },
  {
    id: 73,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 72,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 71,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 70,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 69,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 68,
    ignored: (contextState) => {
      return arrayIncludesNumber(
        Object.values(contextState.selectedBenefitsIds),
        63
      );
    },
  },
  {
    id: 47,
    ignored: (contextState) => {
      if (
        arrayIncludesNumber(
          Object.values(contextState.selectedBenefitsIds),
          37
        )
      ) {
        return true;
      }
      return false;
    },
  },
  {
    id: 44,
    ignored: (contextState) => {
      let ignored = false;
      if (contextState.addDependant) {
        for (const dependant of contextState.dependants) {
          if ( arrayIncludesNumber(getCountryGroup(GREAT).list, dependant.country)) {
            ignored = true;
          }
        }
      }
      return ignored;
    },
  },
  {
    id: 46,
    ignored: (contextState) => {
      let ignored = false;
      if (
        arrayIncludesNumber(
          Object.values(contextState.selectedBenefitsIds),
          37
        )
      ) {
        return true;
      }
      if (contextState.addDependant) {
        for (const dependant of contextState.dependants) {
          if ( arrayIncludesNumber(getCountryGroup(GREAT).list, dependant.country)) {
            ignored = true;
          }
          if (["76"].includes(dependant.country)) {
            ignored = true;
          }
        }
      }
      return ignored;
    },
  },
  {
    id: 37,
    ignored: (contextState) => {
      let ignored = true;
      if (
        arrayIncludesNumber(getCountryGroup(LATAM).list, contextState.country)
      ) {
        ignored = false;
      }
      if (contextState.addDependant) {
        for (const dependant of contextState.dependants) {
          if ( arrayIncludesNumber(getCountryGroup(GREAT).list, dependant.country)) {
            ignored = true;
          }
        }
      }
      return ignored;
    },
  },
].map((i) => {
  if (!i?.ignored) i.ignored = false;
  return i;
});
