import React, { memo, useContext, useState } from "react";
import { TextsContext } from '../context/textsContext';
import { Icon } from "@iconify/react";
import Navbar from "react-bootstrap/Navbar";
import Button from 'react-bootstrap/Button';
import Picture from "./PictureComponent";
import StepComponents from "./StepComponents";
import { TitleH6Component } from "./TitleH6Component";
import StyleHeader from "../scss/header.module.scss";
import StyleTxt from "../scss/text.module.scss";
import StyleBtn from "../scss/btn.module.scss";
import * as rdd from 'react-device-detect';
import Stylegeneral from "../scss/general.module.scss";
import { useCotizador } from "../context/apiContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import Stylemodal from '../scss/modal.module.scss';
import Stylebnt from "../scss/btn.module.scss";
import { Fragment } from "react";
import StepAppComponents from "./application/StepAppComponents";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TitleH3Component } from "./TitleH3Component";
const MenuComponent = memo((props) => {
	const { texts, updateTexts } = useContext(TextsContext);
	const [modalCerrar, setModalCerrar] = useState(false);
	const handleClose = () => setModalCerrar(false);
	const { name, resetData } = useCotizador();
	rdd.isMobile = true;
	const { sethamburger, hamburger, menuHome, menuLogin, phase = "cotizador" } = props;
	const users = sessionStorage.getItem('userdata');
	const navigate = useNavigate();
	const reset = () => {
		resetData();
		navigate("/step1");
	}

	const { logout } = useAuth();
	const handleLogout = () => {
		logout();
		navigate('/login')
	}

	const changehamburger = () => {
		sethamburger(!hamburger);
	};


	return (
		<Fragment>
			<Navbar expand="lg" className={StyleHeader.navbarExpandLg}>
				<div className={` ${StyleHeader.wMobile}`}>
					<div className="d-flex justify-content-between align-items-center">
						<Navbar.Brand href="/home" className={`d-flex align-items-center ${StyleHeader.logo}`}>
							<img className="w-100" src="/img/logo_myvipcaretmvumi.png" alt="VUMI" />
						
						</Navbar.Brand>

						<Navbar.Toggle aria-controls="navbarScroll" onClick={() => changehamburger()} className={`border-0
				             ${StyleHeader.mblToggle}`}>
							{hamburger
								?
								<div className={`${StyleHeader.hamburgerBtn} me-0`}>
									<img className="w-100" src="/img/hamburger.png" alt="VUMI" />
								</div>
								:
								<div className={`${StyleHeader.hamburgerBtn}`}><Picture src="/img/hamburger_close.png" alt="VUMI" /></div>

							}
						</Navbar.Toggle>


					</div>
				</div>

				<div className={`${StyleHeader.wMenuTitle}`} >
					<Navbar.Collapse id="navbarScroll" className={`d-lg-flex flex-lg-wrap ${StyleHeader.fDirection}`}>
						<Navbar className="p-0 ms-4 mt-0 w-100">
							<TitleH6Component styleGroup={`${StyleTxt.titlesMenu} raleway_bold fw_700 lh_30 color_secundary mb-0`}>
								{texts?.menu_greeting ?? 'Welcome back!'}
							</TitleH6Component>
						</Navbar>
						<Navbar className="mx-4 p-0 w-100 d-flex justify-content-between align-items-center">
							<div className="pe-2 d-flex align-items-center">
								<Icon icon="cil:smile" className={`pe-2 ${StyleHeader.bigIcon}`} />
								<TitleH6Component styleGroup={`${StyleTxt.titlesMenu} raleway fw_400 lh_30 color-white mb-0`}>
									{name} {texts?.menu_title ?? 'VIP Care'}
								</TitleH6Component>
							</div>

							{users ? <button onClick={() => { setModalCerrar(true) }} className={`${StyleBtn.logout} ${StyleBtn.btn}`}>
								<OverlayTrigger key={'top'} placement={'top'}
									overlay={<Tooltip id="tooltip-top" className="custom-tooltip_logout">
										{texts?.boton_logout ?? 'Cerrar Sesion'}</Tooltip>}>
								
									<Icon icon='mdi:logout' />
								</OverlayTrigger>
							</button> : null}
						</Navbar>
						{menuLogin ? null : <Navbar className="pt-3 pb-2 w-100">
							<Button onClick={reset} className={`raleway fw_700 color_primary bg_gray_3 mb-0 mx-3 px-2 w-100 ${StyleTxt.p}
					${StyleHeader.wTMenu}`}>
								<Icon icon="ei:plus" className={`pe-1 ${StyleHeader.bigIcon}`} />
								{texts?.menu_phrase ?? 'Start over with quote'}
							</Button>
						</Navbar>}
						<Navbar className={` d-none d-lg-block w-100 ${Stylegeneral.borderList}`}>
							{
								phase === "cotizador" ? <StepComponents /> : <StepAppComponents />
							}
						</Navbar>
						{menuHome || menuLogin ? <div className="mx-4 d-flex flex-wrap justify-content-between align-items-center w-100">
							<Navbar.Toggle aria-controls="navbarScroll" onClick={() => changehamburger()} className={`border-0  p-0`}>

								<div className='d-flex align-items-center  mt-3' >
									<div className={StyleHeader.iconidioma}>
										<Icon icon={'twemoji:flag-spain'} />
									</div>
									<button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('es') }}>Español</button>
								</div>
							</Navbar.Toggle>

							<Navbar.Toggle aria-controls="navbarScroll" onClick={() => changehamburger()} className={`border-0 p-0`}>

								<div className='d-flex align-items-center  mt-3'>
									<div className={StyleHeader.iconidioma}>
										<Icon icon={'twemoji:flag-england'} />
									</div>
									<button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('en') }}>English</button>

								</div>
							</Navbar.Toggle>

							<Navbar.Toggle aria-controls="navbarScroll" onClick={() => changehamburger()} className={`border-0 p-0`}>

								<div className='d-flex align-items-center  mt-3'>
									<div className={StyleHeader.iconidioma}>
										<Icon icon={'twemoji:flag-portugal'} />
									</div>
									<button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('pt') }}>Português</button>
								</div>
							</Navbar.Toggle>
						</div> : null}
					</Navbar.Collapse>

				</div>

			</Navbar>

			<Modal show={modalCerrar} onHide={handleClose} className=" d-flex justify-content-center align-items-center modal-share">

				<Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
					<div className="d-flex align-items-center justify-content-end w-100 mb-0" >
						<div className={`  ${Stylemodal.iconClose} `}>
							<Icon icon='mdi:close' onClick={() => { handleClose() }} />
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center w-100 pb-4">
						<TitleH3Component styleGroup={`raleway mb-0 py-0 color_primary_2 mb-0 fw_700   me-2  ${Stylemodal.textShere}`}>
							{texts?.menu_logout_phrase ?? '¿Estás seguro que deseas cerrar sesión?'}
						</TitleH3Component>
					</div>


					<div className="d-flex justify-content-between align-items-center">
						<button type="button" className={`Poppins fw_500 color_primary px-4 py-3  ${StyleBtn.btn} ${StyleBtn.btnPopup} d-flex justify-content-center align-items-center`} onClick={handleLogout}> <span className='me-2'><Icon icon='mdi:logout' /></span>   <span>{texts?.menu_logout ?? 'Cerrar sesión'}</span></button>
						<button type="button" className={`Poppins fw_500 color_primary px-4 py-3 ms-2 ${StyleBtn.btn} ${StyleBtn.btnPopup} d-flex justify-content-center align-items-center`} onClick={handleClose}><span>{texts?.general_cancel_button ?? 'Cancel'}</span></button>
					</div>
				</Modal.Body>
			</Modal>


		</Fragment>
	);
});

export default MenuComponent;
