import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import style from '../../scss/login.module.scss';
import VideoCover from 'react-video-cover';
import Stylegeneral from '../../scss/general.module.scss';
import { Icon } from "@iconify/react";
import Stylebnt from "../../scss/btn.module.scss";
import { TextsContext } from '../../context/textsContext';
import { useNavigate } from "react-router-dom";
import StyleBtn from "../../scss/btn.module.scss";
import MenuComponent from "../../components/MenuComponent";
import StyleGrid from "../../scss/grid.module.scss";
import { useAuth } from "../../context/userContext";
import { Modal } from "react-bootstrap";
import { TitleH3Component } from "components/TitleH3Component";
import Stylemodal from '../../scss/modal.module.scss';
import { BrowserView, MobileView } from "react-device-detect";
import { LoginForm } from "./components/LoginForm";
import { ComingSoon } from "./components/ComingSoon";
import Loader from "components/LoaderComponent";

const LoginPage = () => {
    const { texts, updateTexts, verifyCurrentLanguage, loaded } = useContext(TextsContext);
    const navigate = useNavigate();
    const { user } = useAuth();
    const hHeader = useRef(null);
    const [hamburger, sethamburger] = useState(true)

    const [modalvalidation, setModalvalidation] = useState(false);
    const handleClose = () => setModalvalidation(false);

    let canShow = true;
    useEffect(() => {
        if (user) {
            navigate("/home");
        }
    }, [])

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    try {
        if (loaded && JSON.parse(texts?.des_key) === true) {
            canShow = false;
        }
    } catch (error) {
        canShow = true;
    }

    return (
        <Fragment>
            <div className={style.home}>
                {/* {isMobile && isIOS && 
                    <div>
                        es mobile
                    </div>
                } */}
                <MobileView className={style.videoFondoContent}>
                    <img src={'/img/fondo-video.jpg'} alt={'img fondo login'} className={style.videoFondo} />
                </MobileView>
                <BrowserView>
                    <VideoCover
                        videoOptions={{
                            src: '/video/home.mp4',
                            autoPlay: true,
                            loop: true,
                            muted: true,
                        }}
                        className={style.video}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </BrowserView>
                
                <div className={style.contenidoLogin}>
                    <div className={` d-md-none ${StyleGrid.wpMenu} ${!hamburger ? StyleGrid.active : null}`} ref={hHeader}>
                        <div className={StyleGrid.wpScroll}>
                            <MenuComponent sethamburger={sethamburger} hamburger={hamburger} menuLogin />
                        </div>
                    </div>
                    <section>
                        <div className="container d-none d-md-block">
                            <header className='py-4'>
                                <div className="container">
                                    <div className={style.headerbtn}>
                                        <img className={` ${Stylegeneral.logoh}`} src={'/img/VUMI_WhiteC.svg'} alt={'img logo vumi'} />
                                        <div className='d-md-flex align-items-center d-none '>

                                            <div className='d-flex align-items-center me-4'>
                                                <div className={style.icon}>
                                                    <Icon icon={'twemoji:flag-spain'} />
                                                </div>
                                                <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('es') }}>Español</button>
                                            </div>
                                            <div className='d-flex align-items-center me-4'>
                                                <div className={style.icon}>
                                                    <Icon icon={'twemoji:flag-england'} />
                                                </div>
                                                <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('en') }}>English</button>

                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className={style.icon}>
                                                    <Icon icon={'twemoji:flag-portugal'} />
                                                </div>
                                                <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('pt') }}>Português</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </section>

                     {!loaded ? <Loader /> : (
                        <>
                            <LoginForm setModalvalidation={setModalvalidation} canShow={canShow} />
                        </>
                     )}   
                    <section>
                        
                    </section>
                    <section>
                        <Modal show={modalvalidation} onHide={handleClose} className=" d-flex justify-content-center align-items-center modal-share">

                            <Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
                                <div className="d-flex align-items-center justify-content-end w-100 mb-0" >
                                    <div className={`  ${Stylemodal.iconClose} `}>
                                        <Icon icon='mdi:close' onClick={() => { handleClose() }} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center w-100 pb-4">
                                    <TitleH3Component styleGroup={`raleway mb-0 py-0 color_primary_2 mb-0 fw_700   me-2  ${Stylemodal.textShere}`}>
                                        { 'Usuario o Contraseña incorrecta '}
                                    </TitleH3Component>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <button type="button" className={`Poppins fw_500 color_primary px-4 py-3  ${StyleBtn.btn} ${StyleBtn.btnPopup} d-flex justify-content-center align-items-center`} onClick={handleClose}><span>{texts?.modal_boton_closed ?? 'Closed'}</span></button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </section>
                </div>


            </div>
        </Fragment>
    )
}

export default LoginPage;