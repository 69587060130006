
export const LATAM = "latam";
export const GREAT = "great";

/**
 * @typedef {Object} CountryGroup
 * @property {String} ref
 * @property {Number[]} list
 */

/**
 * @type {CountryGroup[]}
 */
export const countryGroups = [
  {
    ref: LATAM,
    list: [
      32, 68, 76, 152, 170, 188, 214, 222, 320, 332, 340, 484, 558, 600, 604, 659, 662, 670, 858, 862
    ],
  },
  {
    ref: GREAT,
    list: [756, 826, 276, 840, 124, 344, 392, 156, 702]
  }
];

/**
 * 
 * @param {String} CountryGroupRef 
 * @returns {CountryGroup}
 */
export const getCountryGroup = (CountryGroupRef) => {
  return countryGroups.find(i => i.ref === CountryGroupRef);
}
