export const debugBenefits = [
    
    {
        "factorId.nameFactor": "Refractive eye surgery",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "No",
        "factorId.benefitId.code": "EYE111",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "eye_surgery",
        "isNoCoverageOption": false,
        "id": 1261,
        "factor": "0.0000",
        "factorId.id": 2711
    },
    {
        "factorId.nameFactor": "Refractive eye surgery",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$2,500",
        "factorId.benefitId.code": "EYE111",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "eye_surgery",
        "isNoCoverageOption": false,
        "id": 1262,
        "factor": "0.0200",
        "factorId.id": 2711
    },
    {
        "factorId.nameFactor": "Bariatric surgery and/or weight loss medication",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$10,000",
        "factorId.benefitId.code": "BEREATRICSURGERY",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "Bariatric_surgery",
        "isNoCoverageOption": false,
        "id": 12714,
        "factor": "0.0300",
        "factorId.id": 2712
    },
    {
        "factorId.nameFactor": "Bariatric surgery and/or weight loss medication",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$25,000",
        "factorId.benefitId.code": "BEREATRICSURGERY",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "Bariatric_surgery",
        "isNoCoverageOption": false,
        "id": 12713,
        "factor": "0.0400",
        "factorId.id": 2712
    },
    {
        "factorId.nameFactor": "Bariatric surgery and/or weight loss medication",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$50,000",
        "factorId.benefitId.code": "BEREATRICSURGERY",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "Bariatric_surgery",
        "isNoCoverageOption": false,
        "id": 12712,
        "factor": "0.0500",
        "factorId.id": 2712
    },
    {
        "factorId.nameFactor": "Bariatric surgery and/or weight loss medication",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "No",
        "factorId.benefitId.code": "BEREATRICSURGERY",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "Bariatric_surgery",
        "isNoCoverageOption": false,
        "id": 12711,
        "factor": "0.0000",
        "factorId.id": 2712
    },
    {
        "factorId.nameFactor": "Deductible Reduction of up to $5,000",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "Yes",
        "factorId.benefitId.code": "DEDUCTIBLEREDUCTION",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 1371,
        "factor": "0.2000",
        "factorId.id": 2812
    },
    {
        "factorId.nameFactor": "Deductible Reduction of up to $5,000",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "No",
        "factorId.benefitId.code": "DEDUCTIBLEREDUCTION",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 1372,
        "factor": "0.0000",
        "factorId.id": 2812
    },
    {
        "factorId.nameFactor": "Inpatient mental health treatment",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "No",
        "factorId.benefitId.code": "INPATIENT",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 13731,
        "factor": "0.0000",
        "factorId.id": 2813
    },
    {
        "factorId.nameFactor": "Inpatient mental health treatment",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$50,000.00",
        "factorId.benefitId.code": "INPATIENT",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 13731,
        "factor": "0.0100",
        "factorId.id": 2813
    },
    {
        "factorId.nameFactor": "Inpatient mental health treatment",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$100,000.00",
        "factorId.benefitId.code": "INPATIENT",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 13732,
        "factor": "0.0200",
        "factorId.id": 2813
    },
    {
        "factorId.nameFactor": "Inpatient mental health treatment",
        "factorId.factorTypeId.codeFactor": null,
        "factorId.factorTypeId.description": null,
        "description": "$150,000.00",
        "factorId.benefitId.code": "INPATIENT",
        "version": 5,
        "codeFactorOption": null,
        "_parent": [],
        "factorId.portalType": "deductible_reduction",
        "isNoCoverageOption": false,
        "id": 13733,
        "factor": "0.1000",
        "factorId.id": 2813
    },
    {
    
        "factorId.nameFactor": "Maximum outpatient coverage per insured, per policy year",
        "factorId.factorTypeId.codeFactor": "9",
        "factorId.factorTypeId.description": "Outpatient Coverage",
        "factorId.id": 19,
        "factorId.portalType": "outpatient_coverage",
        "description": "Until limit",
        "isNoCoverageOption": false,
        "id": 2214,
        "version": 1,
        "factor": "1.0000",
        "codeFactorOption": "OUTCOVUNLIM"
    },
    {
    
        "factorId.nameFactor": "Maximum outpatient coverage per insured, per policy year",
        "factorId.factorTypeId.codeFactor": "9",
        "factorId.factorTypeId.description": "Outpatient Coverage",
        "factorId.id": 19,
        "factorId.portalType": "outpatient_coverage",
        "description": "$10,000.00",
        "isNoCoverageOption": false,
        "id": 2213,
        "version": 1,
        "factor": "1.1000",
        "codeFactorOption": "OUTCOVUNLIM"
    },
    {
    
        "factorId.nameFactor": "Maximum outpatient coverage per insured, per policy year",
        "factorId.factorTypeId.codeFactor": "9",
        "factorId.factorTypeId.description": "Outpatient Coverage",
        "factorId.id": 19,
        "factorId.portalType": "outpatient_coverage",
        "description": "$50,000.00",
        "isNoCoverageOption": false,
        "id": 2212,
        "version": 1,
        "factor": "1.2000",
        "codeFactorOption": "OUTCOVUNLIM"
    },
    {
    
        "factorId.nameFactor": "Maximum outpatient coverage per insured, per policy year",
        "factorId.factorTypeId.codeFactor": "9",
        "factorId.factorTypeId.description": "Outpatient Coverage",
        "factorId.id": 19,
        "factorId.portalType": "outpatient_coverage",
        "description": "$250,000.00",
        "isNoCoverageOption": false,
        "id": 2211,
        "version": 1,
        "factor": "1.3000",
        "codeFactorOption": "OUTCOVUNLIM"
    },
    
];