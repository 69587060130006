import React, {createContext, useEffect, useState} from "react";
import { getTexts } from "../api/textsApi";
import { useDataParams } from "hooks/DataParamsHook";

export const TextsContext = createContext();

export const TextsProvider = ({ children }) => {
    const [texts, setTexts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {data} = useDataParams();

    const updateTexts = (locale) => {
        getTexts(locale).then((response) => {
            setTexts(response);
            setLoaded(true);
            localStorage.setItem('locale', locale);
        }).catch((e) => {
            console.error(e);
            setLoaded(true);
        });
    }

    const updateToParamLanguage = () => {
        const locale = data?.lang;
        if (locale) {
            updateTexts(locale);
        }
    }

    const thereIsParamLang = () => {
        return !!data?.lang;
    }

    const verifyCurrentLanguage = () => {
        if (thereIsParamLang()) {
            updateToParamLanguage();
        } else if(localStorage.getItem('locale') && texts.length === 0){
            updateTexts(localStorage.getItem('locale'));
        } else if(texts.length === 0) {
            updateTexts("en");
        }
    }

    const getCurrentLanguage = () => {
        return localStorage.getItem('locale') ?? 'en';
    }

    useEffect(() => {
        console.log({data});
        verifyCurrentLanguage();
    }, [data])
    
    return (
        <TextsContext.Provider value={{ 
            texts,
            updateTexts,
            getCurrentLanguage,
            verifyCurrentLanguage,
            loaded
        }}>{ children }</TextsContext.Provider>
    )
}
