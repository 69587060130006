export const debugCountries = [
  {
    id: 1000,
    description: "ECUADOR",
    isoNum: "218",
    version: 0,
  },
  {
    id: 1002,
    description: "PANAMA",
    isoNum: "591",
    version: 0,
  },
  {
    id: 1003,
    description: "ESTADOS UNIDOS",
    isoNum: "840",
    version: 0,
  },
  {
    id: 1004,
    description: "CANADA",
    isoNum: "124",
    version: 0,
  },
  {
    id: 1005,
    description: "HONG KONG",
    isoNum: "344",
    version: 0,
  },
  {
    id: 1006,
    description: "JAPON",
    isoNum: "392",
    version: 0,
  },
  {
    id: 1007,
    description: "CHINA",
    isoNum: "156",
    version: 0,
  },
  {
    id: 1008,
    description: "SINGAPUR",
    isoNum: "702",
    version: 0,
  },
];
